import React from 'react';
import moment from 'moment';
import { ContainerSize, OnHireMapByTerminal } from '@client/_blessed/store/entities';
import LetterBadge from '@client/components/LetterBadge';
import { EmptyReturnSupported } from '@client/_blessed/models';

interface EmptyReturnTerminalsTableProps {
  container: { size: string; sslUuid: string; containerNumber: string };
  containerSizes: Record<string, ContainerSize>;
  emptyReturnsSupported: EmptyReturnSupported[];
  terminals: Record<string, { uuid: string; nickname: string }>;
  onHireStatusMapByTerminal: OnHireMapByTerminal;
}

function EmptyReturnTerminalsTable(props: EmptyReturnTerminalsTableProps) {
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
  const containerSizeUuid = ((props.containerSizes || {})[props.container.size] || { uuid: '' }).uuid;
  const matches = props.emptyReturnsSupported.filter((er: any) => (
      er.sslUuid === props.container.sslUuid && er.sizeUuid === containerSizeUuid && props.terminals[er.terminalUuid]
    ));

  const createTerminalsList = (ers: EmptyReturnSupported[], onHireStatusMapByTerminal: OnHireMapByTerminal) => (
      <div className="YardContainers__emptyReturnMatchesTable-shift">
        {ers.map((er, i: number) => {
          const { terminalUuid } = er;
          const isOnHire = !!onHireStatusMapByTerminal[terminalUuid];
          const terminalName = props.terminals[terminalUuid].nickname;
          return (
            <div
              className={`YardContainers__emptyReturnMatchesTable-shift-list-item ${
                !isOnHire && 'YardContainers__emptyReturnMatchesTable-shift-list-item--red'
              }`}
            >
              {!isOnHire && (
                <LetterBadge
                  className="YardContainers__not-on-hire-badge"
                  text="N"
                  toolTipId="not-on-hire"
                  toolTipMessage="Not On-hired"
                />
              )}
              {terminalName}
              {i !== ers.length - 1 && ', '}
            </div>
          );
        })}
      </div>
    );

  return (
    <table className="YardContainers__emptyReturnMatchesTable">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>{today}</th>
          <th>{tomorrow}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>First</th>
          <td>
            {createTerminalsList(
              matches.filter((match: any) => match.returnDate === today && match.shift === 0),
              props.onHireStatusMapByTerminal
            )}
          </td>
          <td>
            {createTerminalsList(
              matches.filter((match: any) => match.returnDate === tomorrow && match.shift === 0),
              props.onHireStatusMapByTerminal
            )}
          </td>
        </tr>
        <tr>
          <th>Second</th>
          <td>
            {createTerminalsList(
              matches.filter((match: any) => match.returnDate === today && match.shift === 1),
              props.onHireStatusMapByTerminal
            )}
          </td>
          <td>
            {createTerminalsList(
              matches.filter((match: any) => match.returnDate === tomorrow && match.shift === 1),
              props.onHireStatusMapByTerminal
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default EmptyReturnTerminalsTable;
