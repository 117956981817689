import { YardContainerInfoWithConfirmation } from '@client/_blessed/models/YardContainerInfo';

export const sliceID = 'yardContainersPage';

type Column = keyof YardContainerInfoWithConfirmation;

export const sortBy =
  (sort: { column: Column; direction: 'ASC' | 'DESC' }) =>
  (a: YardContainerInfoWithConfirmation, b: YardContainerInfoWithConfirmation) => {
    let aValue: string | number = (a[sort.column] || '').toString();
    let bValue: string | number = (b[sort.column] || '').toString();

    // if sorting by confirmed and a.confirmed and b.confirmed are both false, use the discrepancy message for sorting
    if (sort.column === 'confirmed' && !aValue && !bValue) {
      aValue = a.containerYardConfirmation?.discrepancy?.toString() || '';
      bValue = b.containerYardConfirmation?.discrepancy?.toString() || '';
    }

    if (sort.column === 'perDiemEstimate') {
      aValue = a.perDiemEstimate ? Number(a.perDiemEstimate) : 0;
      bValue = b.perDiemEstimate ? Number(b.perDiemEstimate) : 0;
    }

    if (sort.direction.toUpperCase() === 'ASC') {
      return aValue < bValue ? -1 : 1;
    }

    return aValue > bValue ? -1 : 1;
  };

export const filterWith = (value: string) => (yard: YardContainerInfoWithConfirmation) => {
  const matchValue = value.toUpperCase();
  const match =
    yard.containerNumber.toUpperCase().includes(matchValue) ||
    (yard.sslName || '').toUpperCase().includes(matchValue) ||
    (yard.notes || '').toUpperCase().includes(matchValue) ||
    (yard.importExport || '').toUpperCase().includes(matchValue) ||
    (yard.yardName || '').toUpperCase().includes(matchValue) ||
    (yard.terminalName && yard.terminalName.toUpperCase().includes(matchValue)) ||
    (yard.inboundAppointmentStart && yard.inboundAppointmentStart.toUpperCase().includes(matchValue)) ||
    (yard.carrierName && yard.carrierName.toUpperCase().includes(matchValue)) ||
    (yard.driverFirstName && yard.driverFirstName.toUpperCase().includes(matchValue)) ||
    (yard.driverLastName && yard.driverLastName.toUpperCase().includes(matchValue)) ||
    (yard.chassisNumber && yard.chassisNumber.toUpperCase().includes(matchValue)) ||
    (yard.chassisProviderName && yard.chassisProviderName.toUpperCase().includes(matchValue)) ||
    (yard.reservedByUserName && yard.reservedByUserName.toLocaleUpperCase().includes(matchValue)) ||
    (yard.inboundAppointmentNumber && yard.inboundAppointmentNumber.toLocaleUpperCase().includes(matchValue));

  return match;
};
