import { ContainerYardConfirmationUserResponse } from '@client/_blessed/store/entities/containerYardConfirmations';

export function getName(confirmedByUser?: ContainerYardConfirmationUserResponse) {
  const firstName = confirmedByUser?.firstName || '';
  const lastName = confirmedByUser?.lastName || '';

  return `${firstName} ${lastName}`.trim();
}

export function filterMutedAndUnvailableContainers({
  tableData,
  showMuted,
  showAllContainers,
  mutedItems
}: {
  tableData: any[];
  showMuted: boolean;
  showAllContainers: boolean;
  mutedItems: string[];
}): any[] {
  const result = tableData
    .filter((container: any) => showMuted || !mutedItems.includes(container.containerNumber))
    .filter((container) => showAllContainers || container.outboundLegStatus === 'leg_created');
  return result;
}
