// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.openDispatchPageBtn__anchor {
    text-decoration: none;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/YardContainers/components/OpenContainersInDispatchPageButton/styles.css"],"names":[],"mappings":"AACE;IACE,qBAAqB;EACvB","sourcesContent":[".openDispatchPageBtn {\n  &__anchor {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
