import { combineReducers } from '@reduxjs/toolkit';
import empty, { sliceId as emptySlice } from '../features/EmptyReturnAcceptance/store';
import generator from '../features/BundleGenerator/store';
import generatorNotifications, {
  sliceId as generatorNotificationsSlice
} from '../features/BundleGeneratorNotifications/store';
import reactive, { sliceId as reactiveSlice } from '../features/ReactiveDualTrans/store';
import dualTransFinder from '../features/DualTransFinder/store';
import { sliceId as dualTransFinderSlice } from '../features/DualTransFinder/store/constants';
import subscriptionNotifications, {
  sliceId as subscriptionNotificationsSlice
} from '../features/Scraper/SubscriptionNotification/stores';
import { sliceID as yardContainersSlice } from '../features/YardContainers/store/utils';
import yardContainers from '../features/YardContainers/store';
import adminActionLogs from '../features/ActionLogs/store';
import shipETASlice, { shipETASliceId } from '../features/ShipETAs/store';
import bulkOperationUpdatesStore from '../features/BulkOperationPage/store';

// eslint-disable-next-line import/export
export const sliceId = 'admin';

const adminPageReducer = combineReducers({
  [emptySlice]: empty.reducer,
  bundleGenerator: generator.reducer,
  [generatorNotificationsSlice]: generatorNotifications.reducer,
  [dualTransFinderSlice]: dualTransFinder.reducer,
  [reactiveSlice]: reactive.reducer,
  [subscriptionNotificationsSlice]: subscriptionNotifications.reducer,
  [yardContainersSlice]: yardContainers.reducer,
  adminActionLogs,
  [shipETASliceId]: shipETASlice.reducer,
  bulkOperationUpdates: bulkOperationUpdatesStore.reducer
});

// eslint-disable-next-line import/export
export * from '../features/EmptyReturnAcceptance/store';

export default adminPageReducer;
