import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import store from '../../store';
import {
  shippingLinesSelector,
  sizesSelector,
  terminalIngatessSelector,
  terminalOutgatessSelector,
  yardsSelector
} from '../../store/selectors';

function Container() {
  const dispatch = useAppDispatch();
  const shippingLines = useSelector(shippingLinesSelector);
  const sizes = useSelector(sizesSelector);
  const terminalOutgates = useSelector(terminalOutgatessSelector);
  const terminalIngates = useSelector(terminalIngatessSelector);
  const yards = useSelector(yardsSelector);

  const handleFilter = (value: any) => {
    dispatch(store.actions.filter(value));
  };

  return (
    <Component
      shippingLines={shippingLines}
      sizes={sizes}
      terminalOutgates={terminalOutgates}
      terminalIngates={terminalIngates}
      yards={yards}
      onFilterChange={handleFilter}
    />
  );
}

export default React.memo(Container);
