import React from 'react';
import moment from 'moment-timezone';
import Button from '@client/components/Button';
import { TIME_ZONES } from '@drayalliance/types';
import { YardContainer } from '../YardConfirmationSidebarButton/types';
import { getName } from '../../utils';

import './style.css';

interface Props {
  yardContainer: YardContainer;
  // eslint-disable-next-line react/no-unused-prop-types
  onConfirm: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onCancelConfirmation: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onReserve: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onCancelReservation: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onSubmitNotes: (notes: string) => void;
}

const formatDateTime = (date: string, timezone?: TIME_ZONES) =>
  moment(date)
    .tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES)
    .format('YYYY-MM-DD HH:mm');

function YardConfirmationInfo({
  yardContainer,
  onConfirm,
  onCancelConfirmation
}: {
  yardContainer: YardContainer;
  onConfirm: () => void;
  onCancelConfirmation: () => void;
}) {
  if (yardContainer.containerYardConfirmation?.confirmedByUser) {
    return (
      <>
        <div className="yard-confirmation-sidebar-columns__title">Confirmed By</div>
        <div>{getName(yardContainer.containerYardConfirmation?.confirmedByUser)}</div>
        <div>
          {yardContainer.containerYardConfirmation?.confirmedAt &&
            formatDateTime(yardContainer.containerYardConfirmation?.confirmedAt)}
        </div>
        <Button label="Cancel" onClick={onCancelConfirmation} />
      </>
    );
  }
  return (
    <>
      <div className="yard-confirmation-sidebar-columns__title">Confirmed By</div>
      <div>N/A</div>
      <Button theme="2" label="Confirm" onClick={onConfirm} />
    </>
  );
}

function YardReservationInfo({
  yardContainer,
  onReserve,
  onCancelReservation
}: {
  yardContainer: YardContainer;
  onReserve: () => void;
  onCancelReservation: () => void;
}) {
  if (yardContainer.containerYardConfirmation?.reservedByUser) {
    return (
      <>
        <div className="yard-confirmation-sidebar-columns__title">Reserved By</div>
        <div>{getName(yardContainer.containerYardConfirmation?.reservedByUser)}</div>
        <div>
          {yardContainer.containerYardConfirmation?.reservedAt &&
            formatDateTime(yardContainer.containerYardConfirmation?.reservedAt)}
        </div>
        <Button label="Cancel" onClick={onCancelReservation} />
      </>
    );
  }
  return (
    <>
      <div className="yard-confirmation-sidebar-columns__title">Reserved By</div>
      <div>N/A</div>
      <Button theme="2" label="Reserve" onClick={onReserve} />
    </>
  );
}

export function YardConfirmationMetaData({
  yardContainer,
  onConfirm,
  onCancelConfirmation,
  onReserve,
  onCancelReservation
}: Props) {
  return (
    <div>
      <div className="yard-confirmation-sidebar-columns">
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Container</div>
          <div>{yardContainer.containerYardConfirmation?.containerNumber}</div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Check In</div>
          <div>
            {yardContainer.containerYardConfirmation?.checkInDate
              ? formatDateTime(yardContainer.containerYardConfirmation?.checkInDate)
              : 'N/A'}
          </div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Last Check</div>
          <div>
            {yardContainer.containerYardConfirmation?.updatedAt
              ? formatDateTime(yardContainer.containerYardConfirmation?.updatedAt)
              : 'N/A'}
          </div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Discrepancy</div>
          <div>{yardContainer.containerYardConfirmation?.discrepancy || 'No Discrepancy'}</div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Category</div>
          <div>{yardContainer.containerYardConfirmation?.category || 'N/A'}</div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Location</div>
          <div>{yardContainer.containerYardConfirmation?.location || 'N/A'}</div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <div className="yard-confirmation-sidebar-columns__title">Booking</div>
          <div>{yardContainer.containerYardConfirmation?.booking || 'N/A'}</div>
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <YardConfirmationInfo
            yardContainer={yardContainer}
            onConfirm={onConfirm}
            onCancelConfirmation={onCancelConfirmation}
          />
        </div>
        <div className="yard-confirmation-sidebar-columns__column">
          <YardReservationInfo
            yardContainer={yardContainer}
            onReserve={onReserve}
            onCancelReservation={onCancelReservation}
          />
        </div>
      </div>
    </div>
  );
}

function YardConfirmationForm(props: Props) {
  const [notes, setNotes] = React.useState(props.yardContainer.containerYardConfirmation?.notes || '');
  return (
    <form
      className="yard-confirmation-form"
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmitNotes(notes);
      }}
    >
      <label htmlFor="yard-confirmation-notes">Notes:</label>
      <textarea
        id="yard-confirmation-notes"
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        aria-label="Yard confirmation notes"
      />
      <Button label="Save" theme="2" submit />
    </form>
  );
}

function YardConfirmationSidebar(props: Props) {
  return (
    <div className="yard-confirmation-sidebar">
      <h1>Yard Confirmation</h1>
      {props.yardContainer?.containerYardConfirmation ? (
        <div>
          <YardConfirmationMetaData {...props} />
          <YardConfirmationForm {...props} />
        </div>
      ) : (
        <div>No Record Found</div>
      )}
    </div>
  );
}

export default YardConfirmationSidebar;
