import React, { useEffect, useState, createRef, RefObject } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import exportFromJSON from 'export-from-json';
import { legStatusesToUIMappings } from '@client/constants';
import Button from '@client/components/Button';
import Drawer from '@client/components/Drawer';
import CopyButton from '@client/components/CopyButton';
import {
  EmptyReturnSupported,
  YardContainerInfoWithConfirmation,
  YardContainerInfo
} from '@client/_blessed/models/YardContainerInfo';
import IconButton from '@client/components/IconButton';
import ExportIcon from '@components/deprecatedTookit/icons/fa/regular/DownloadIcon';
import {
  ContainerOnStatusEntity,
  ContainerSize,
  fetchOneContainerOnHireStatus,
  GeneratorLeg
} from '@client/_blessed/store/entities';
import { SmartMatch } from '@client/_blessed/models/SmartMatch';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingSpinner from '@client/components/LoadingSpinner';
import { logAnalyticsClick } from '@client/utils/analytics';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { selectGeneratorMatchesLoading } from './store/selectors';
import YardContainersTable from './components/YardContainersTable';
import YardContainerFilters from './features/YardContainersFilters';
import EmptyReturnTerminalsTable from './components/EmptyReturnTerminalsTable';
import MatchRow, { MatchRowMatch } from './components/MatchRow/component';
import OpenContainersInDispatchPageButton from './components/OpenContainersInDispatchPageButton';

import './style.css';

interface YardContainersProps {
  containerSizes: Record<string, ContainerSize>;
  emptyReturnsSupported: EmptyReturnSupported[];
  terminals: Record<string, { uuid: string; nickname: string }>;
  tableData?: any;
  tableDataLoading?: any;
  tableSort?: any;
  useNewBundles: boolean;
  generatorMatches: GeneratorLeg[];
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>;
  onRefresh?: () => void;
  // eslint-disable-next-line no-unused-vars
  onSearch?: (value: any) => void;
  // eslint-disable-next-line no-unused-vars
  onTableSort?: (value: any) => void;
  // eslint-disable-next-line no-unused-vars
  onLoadSmartMatches: (params: { ssl: string; size: string; legNumber: number }) => void;
  smartMatches: Record<string, SmartMatch[]>;
}

function YardContainers(props: YardContainersProps) {
  const getYardFiltersContainerHeight = (ref: RefObject<HTMLDivElement>) => {
    const [height, setHeight] = useState(150);
    useEffect(() => {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        const boundingRectHeight = boundingRect.height;
        setHeight(boundingRectHeight);
      }
    }, [ref]);
    return height;
  };

  const featureFlags = useFlags();
  const [selectedContainer, selectContainer] = useState<any>(null);
  const [matchSort, setMatchSort] = useState<keyof SmartMatch | keyof GeneratorLeg>('legNumber');
  const [matchSortDirection, setMatchSortDirection] = useState<'ASC' | 'DESC'>('ASC');
  const [muted, setMuted] = useState<string[]>(JSON.parse(localStorage.getItem('yardContainers.muted') || '[]'));
  const [showAllContainers, setShowAllContainers] = useState<boolean>(
    JSON.parse(localStorage.getItem('yardContainers.showAllContainers') || 'false')
  );
  const [showMuted, setShowMuted] = useState<boolean>(
    JSON.parse(localStorage.getItem('yardContainers.showMuted') || 'false')
  );
  // const [unsortedMatches, setUnsortedMatches] = useState<(SmartMatch | GeneratorLeg)[]>([]);
  const generatorMatchesIsLoading = useSelector(selectGeneratorMatchesLoading);
  const YardContainerFiltersRef: React.LegacyRef<HTMLDivElement> = createRef();
  const divHeight = getYardFiltersContainerHeight(YardContainerFiltersRef);
  const {
    eft323ContainerYardConfirmations: isAllowedToUseYardConfirmations,
    availableEmptyYardContainerListMaxLength
  } = featureFlags;
  const className = 'YardContainers';
  const tableSortDirection = props.tableSort.direction;
  const { tableData } = props;
  const sortedColumn = props.tableSort.column;
  const dispatch = useAppDispatch();

  const onHireStatusMapByTerminal =
    props.onHireStatusesByContainer?.[selectedContainer?.containerNumber]?.onHireStatusByTerminal || {};

  useEffect(() => {
    localStorage.setItem('yardContainers.muted', JSON.stringify(muted));
  }, [muted]);
  useEffect(() => {
    if (!isAllowedToUseYardConfirmations) {
      localStorage.removeItem('yardContainers.showAllContainers');
    } else {
      localStorage.setItem('yardContainers.showAllContainers', JSON.stringify(showAllContainers));
    }
  }, [showAllContainers, isAllowedToUseYardConfirmations]);
  useEffect(() => {
    localStorage.setItem('yardContainers.showMuted', JSON.stringify(showMuted));
  }, [showMuted]);
  useEffect(() => {
    if (selectedContainer) {
      dispatch(
        fetchOneContainerOnHireStatus({
          containerNumber: selectedContainer.containerNumber,
          orderNumber: selectedContainer.orderNumber
        })
      );
      props.onLoadSmartMatches({
        ssl: selectedContainer.sslUuid,
        size: selectedContainer.size,
        legNumber: selectedContainer.outboundLegNumber
      });
    }
  }, [selectedContainer]);
  const handleSelectContainer = (containerNumber: YardContainerInfo | null): void => {
    if (containerNumber?.containerNumber) {
      logAnalyticsClick('select container', 'admin/available-yard-containers/dual-trans-candidates', {
        containerNumber: containerNumber.containerNumber
      });
    }
    selectContainer(containerNumber);
  };

  const handleSearch = (event: any) => {
    const { value } = event.target;

    if (props.onSearch) {
      props.onSearch(value);
    }
  };

  const toggleColumn = (column: keyof YardContainerInfoWithConfirmation) => {
    if (props.onTableSort) {
      props.onTableSort({
        column,
        direction: (tableSortDirection || '').toUpperCase() === 'ASC' ? 'DESC' : 'ASC'
      });
    }
  };

  const toggleMatchColumn = (column: keyof SmartMatch | keyof GeneratorLeg) => {
    if (column === matchSort) {
      setMatchSortDirection(matchSortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setMatchSort(column);
      setMatchSortDirection('ASC');
    }
  };

  const handleRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh();
    }
  };

  const handleMuted = () => {
    setShowMuted(!showMuted);
    logAnalyticsClick('Show Muted', 'admin/available-yard-containers', { showing: !showMuted });
  };

  const handleShowContainers = () => {
    setShowAllContainers(!showAllContainers);
    logAnalyticsClick('Show Containers', 'admin/available-yard-containers', { showing: !showAllContainers });
  };

  const handleExport = () => {
    const fileName = `available-yard-containers_${moment().format()}`;
    const exportType = 'xls';

    exportFromJSON({ data: tableData, fileName, exportType });
    logAnalyticsClick('export available yard containers', 'admin/available-yard-containers');
  };

  const getMatches = (container: { size: string; sslUuid: string }): EmptyReturnSupported[] => {
    if (!container) return [];
    const containerSizeUuid = ((props.containerSizes || {})[container.size] || { uuid: '' }).uuid;
    return props.emptyReturnsSupported.filter(
      (er: EmptyReturnSupported) =>
        er.sslUuid === container.sslUuid && er.sizeUuid === containerSizeUuid && props.terminals[er.terminalUuid]
    );
  };

  const getMatchingTerminals = (container: { size: string; sslUuid: string }): string[] =>
    Array.from(new Set(getMatches(container).map((match: { terminalUuid: string }) => match.terminalUuid)));

  let unsortedMatches: (SmartMatch | GeneratorLeg)[] = [];

  if (selectedContainer) {
    const [, selectedContainerSize] = selectedContainer.size?.match(/(\d*)([\s\S]*)/) || [];

    if (props.useNewBundles) {
      unsortedMatches = (props.generatorMatches || []).filter(
        (generatorLeg) =>
          generatorLeg.size.startsWith(selectedContainerSize) &&
          Number.isNaN(Number(generatorLeg.size.charAt(selectedContainerSize.length)))
      );
    } else {
      unsortedMatches = unsortedMatches.concat(
        ...getMatchingTerminals(selectedContainer).map((terminalUuid) =>
          // Get the substring of all digits until the first non-numeric character
          // E.g. 40SD => 40.  20HQ => 20.
          // .match returns ["40SD", "40", "SD"] and we want the second item
          (props.smartMatches[terminalUuid] || []).filter(
            (smartMatch) =>
              // If it starts with the same number
              smartMatch.size.startsWith(selectedContainerSize) &&
              // And the next character is not a number (probably not an issue, but prevents
              // something like 40 SD / 400 SD matching
              Number.isNaN(Number(smartMatch.size.charAt(selectedContainerSize.length)))
          )
        )
      );
    }
  }

  const renderHeaders = () => (
    <tr>
      <th onClick={() => toggleMatchColumn('legNumber')}>
        Job # {matchSort === 'legNumber' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('legStatus')}>
        Leg Status {matchSort === 'legStatus' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('driverFirstName')}>
        Driver {matchSort === 'driverFirstName' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('containerNumber')}>
        Container {matchSort === 'containerNumber' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('lastFreeDay')}>
        LFD {matchSort === 'lastFreeDay' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('terminalNickname')}>
        Terminal {matchSort === 'terminalNickname' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('appointmentStart')}>
        Terminal Appt {matchSort === 'appointmentStart' ? `(${matchSortDirection})` : ''}
      </th>
    </tr>
  );

  const renderNewHeaders = () => (
    <tr>
      <th onClick={() => toggleMatchColumn('legNumber')}>
        Job # {matchSort === 'legNumber' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('status')}>
        Leg Status {matchSort === 'status' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('driverName')}>
        Driver {matchSort === 'driverName' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('containerNumber')}>
        Container {matchSort === 'containerNumber' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('lastFreeDay')}>
        LFD {matchSort === 'lastFreeDay' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('firstStopName')}>
        Terminal {matchSort === 'firstStopName' ? `(${matchSortDirection})` : ''}
      </th>
      <th onClick={() => toggleMatchColumn('firstStopStart')}>
        Terminal Appt {matchSort === 'firstStopStart' ? `(${matchSortDirection})` : ''}
      </th>
    </tr>
  );

  const sortMatches = (a: SmartMatch | GeneratorLeg, b: SmartMatch | GeneratorLeg) => {
    const localMatchSort = matchSort as keyof SmartMatch;
    a = a as SmartMatch;
    b = b as SmartMatch;
    if (matchSortDirection === 'ASC') {
      return (a[localMatchSort] || '')
        .toString()
        .trim()
        .localeCompare((b[localMatchSort] || '').toString().trim());
    }
    return (b[localMatchSort] || '')
      .toString()
      .trim()
      .localeCompare((a[localMatchSort] || '').toString().trim());
  };

  const sortNewMatches = (a: SmartMatch | GeneratorLeg, b: SmartMatch | GeneratorLeg) => {
    const localMatchSort = matchSort as keyof GeneratorLeg;
    a = a as GeneratorLeg;
    b = b as GeneratorLeg;
    if (matchSortDirection === 'ASC') {
      return (a[localMatchSort] || '')
        .toString()
        .trim()
        .localeCompare((b[localMatchSort] || '').toString().trim());
    }
    return (b[localMatchSort] || '')
      .toString()
      .trim()
      .localeCompare((a[localMatchSort] || '').toString().trim());
  };

  const rowMapper = (item: SmartMatch | GeneratorLeg) => {
    let match: MatchRowMatch;
    let isOnHire = false;
    if (props.useNewBundles) {
      item = item as GeneratorLeg;
      isOnHire = !!onHireStatusMapByTerminal[item.firstStopDepartmentUuid];
      match = {
        key: item.legNumber,
        legNumber: item.legNumber,
        status: item.status,
        driverName: item.driverName,
        driverPhone: item.driverPhone,
        containerNumber: item.containerNumber,
        lastFreeDay: item.lastFreeDay,
        firstStopName: item.firstStopName,
        firstStopStart: item.firstStopStart
      };
    } else {
      item = item as SmartMatch;
      isOnHire = !!onHireStatusMapByTerminal[item.terminalUuid];
      match = {
        key: item.legUuid,
        legNumber: item.legNumber,
        status: item.legStatus,
        driverName: `${item.driverFirstName} ${item.driverLastName}`,
        driverPhone: item.driverPhone,
        containerNumber: item.containerNumber,
        lastFreeDay: item.lastFreeDay,
        firstStopName: item.terminalNickname,
        firstStopStart: item.appointmentStart
      };
    }
    return <MatchRow match={match} isOnHire={isOnHire} />;
  };

  const headerRenderer = props.useNewBundles ? renderNewHeaders : renderHeaders;
  const matchSorter = props.useNewBundles ? sortNewMatches : sortMatches;

  return (
    <div className={`${className}`}>
      <div className={`${className}__fixed-pane`} ref={YardContainerFiltersRef}>
        <div className={`${className}__options`}>
          <input
            id="filter"
            className="reset-form__input"
            name="filter"
            onChange={_.debounce(handleSearch, 500)}
            aria-label="Reset form input"
          />
          <Button theme="1" label="Refresh" onClick={handleRefresh} />
          <Button
            theme="2"
            label={
              showMuted
                ? 'Showing Muted'
                : `Hiding ${
                    muted.filter(
                      (containerNumber: string) =>
                        !!tableData.find((row: { containerNumber: string }) => row.containerNumber === containerNumber)
                    ).length
                  } Muted`
            }
            onClick={handleMuted}
          />
          {isAllowedToUseYardConfirmations && (
            <Button
              theme="2"
              label={showAllContainers ? 'Showing All Containers' : 'Hiding Unavailable Containers'}
              onClick={handleShowContainers}
            />
          )}
          <IconButton theme="2" icon={<ExportIcon />} onClick={handleExport} />
          {availableEmptyYardContainerListMaxLength > 0 && (
            <OpenContainersInDispatchPageButton
              showMuted={showMuted}
              mutedItems={muted}
              showAllContainers={showAllContainers}
              availableEmptyYardContainerListMaxLength={availableEmptyYardContainerListMaxLength}
            />
          )}
          {props.tableDataLoading && <div>Loading...</div>}
        </div>
        <YardContainerFilters />
      </div>
      <YardContainersTable
        isAllowedToUseYardConfirmations={isAllowedToUseYardConfirmations}
        parentClassName={className}
        showMuted={showMuted}
        toggleColumn={toggleColumn}
        sortedColumn={sortedColumn}
        tableSortDirection={tableSortDirection}
        tableData={tableData}
        muted={muted}
        selectContainer={handleSelectContainer}
        selectedContainer={selectedContainer}
        setMuted={setMuted}
        showAllContainers={showAllContainers}
        offsetHeight={divHeight}
      />
      <Drawer
        anchor="right"
        contentClassName={
          selectedContainer
            ? 'right-sidebar-navigation__content right-sidebar-navigation__content-open'
            : 'right-sidebar-navigation__content'
        }
        open={!!selectedContainer}
        onClose={(e) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
          }
          handleSelectContainer(null);
        }}
      >
        <div className={`${className}__selectedContainer-wrapper`}>
          {selectedContainer && !generatorMatchesIsLoading ? (
            <>
              <h1>Dual Trans Candidates</h1>
              <div className={`${className}__selectedContainer`}>
                <div className={`${className}__selectedContainer-column`}>
                  <span>
                    {selectedContainer.containerNumber} <CopyButton value={`${selectedContainer.containerNumber}`} />
                  </span>
                  <div
                    className={`${className}__selectedContainer-column-row`}
                    style={{ color: 'var(--color-gray-5)', fontWeight: 'normal' }}
                  >
                    {selectedContainer.chassisNumber}
                    {selectedContainer.chassisProviderName && ` (${selectedContainer.chassisProviderName})`}
                  </div>
                  <div
                    className={`${className}__selectedContainer-column-row`}
                    style={{ color: 'var(--color-gray-5)', fontWeight: 'normal' }}
                  >
                    {selectedContainer.sslNickname} | {selectedContainer.size}
                  </div>
                </div>
                <div className={`${className}__selectedContainer-column`}>
                  <span>
                    {selectedContainer.outboundLegNumber} <CopyButton value={selectedContainer.outboundLegNumber} />
                  </span>
                  <div className={`${className}__selectedContainer-column-row`}>
                    {legStatusesToUIMappings[selectedContainer.outboundLegStatus]}
                  </div>
                </div>
                <div className={`${className}__selectedContainer-column`}>
                  {selectedContainer.terminalNickname}
                  <div className={`${className}__selectedContainer-column-row`}>
                    {selectedContainer.isBundled ? 'Bundled' : 'Not Bundled'}
                  </div>
                </div>
                <EmptyReturnTerminalsTable
                  container={selectedContainer}
                  containerSizes={props.containerSizes}
                  emptyReturnsSupported={props.emptyReturnsSupported}
                  terminals={props.terminals}
                  onHireStatusMapByTerminal={onHireStatusMapByTerminal}
                />
              </div>
              {unsortedMatches.length > 0 ? (
                <table className={`${className}__table`}>
                  <thead>{headerRenderer()}</thead>
                  <tbody>{unsortedMatches.sort(matchSorter).map(rowMapper)}</tbody>
                </table>
              ) : (
                <p>No Matches Found</p>
              )}
            </>
          ) : (
            <div className={`${className}__loader`}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default YardContainers;
