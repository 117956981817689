// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yard-confirmation-sidebar {
  width: 100%;
  box-shadow: 0 2px 2px 0 #bbbcbd;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;
  background: white;
}

  .yard-confirmation-sidebar .yard-confirmation-sidebar-columns {
    display: flex;
    font-size: 14px;
  }

  .yard-confirmation-sidebar .yard-confirmation-sidebar-columns__column > * {
        padding-bottom: 5px;
      }

  .yard-confirmation-sidebar .yard-confirmation-sidebar-columns__column {

      border: solid 1px var(--color-gray-3);
      padding: 20px;
      display: flex;
      text-align: center;
      flex-direction: column;
}

  .yard-confirmation-sidebar .yard-confirmation-sidebar-columns__title {
      font-weight: bold;
    }

  .yard-confirmation-sidebar .yard-confirmation-form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .yard-confirmation-sidebar .yard-confirmation-form > * {
      margin: 5px 0;
    }

  .yard-confirmation-sidebar .yard-confirmation-form button {
      width: 80px;
    }

  .yard-confirmation-sidebar .yard-confirmation-form textarea {
      padding: 20px;
      border: 1px solid var(--color-grayscale-5);
      width: 50%;
      height: 200px;
      outline: none;
    }

  .yard-confirmation-sidebar .yard-confirmation-form label {
      font-weight: 500;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/YardContainers/components/YardConfirmationSidebar/style.css","<no source>"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,+BAA+B;EAC/B,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AAgDnB;;EA9CE;IACE,aAAa;IACb,eAAe;EAiBjB;;EAdI;QACE,mBAAmB;MACrB;;ECfN;;MDiBM,qCAAqC;MACrC,aAAa;MACb,aAAa;MACb,kBAAkB;MAClB;CCrBN;;EDwBI;MACE,iBAAiB;IACnB;;EAGF;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAqBlB;;EAnBE;MACE,aAAa;IACf;;EAEA;MACE,WAAW;IACb;;EAEA;MACE,aAAa;MACb,0CAA0C;MAC1C,UAAU;MACV,aAAa;MACb,aAAa;IACf;;EAEA;MACE,gBAAgB;IAClB","sourcesContent":[".yard-confirmation-sidebar {\n  width: 100%;\n  box-shadow: 0 2px 2px 0 #bbbcbd;\n  padding: 20px;\n  height: 100vh;\n  overflow-y: scroll;\n  background: white;\n\n  .yard-confirmation-sidebar-columns {\n    display: flex;\n    font-size: 14px;\n\n    &__column {\n      & > * {\n        padding-bottom: 5px;\n      }\n\n      border: solid 1px var(--color-gray-3);\n      padding: 20px;\n      display: flex;\n      text-align: center;\n      flex-direction: column;\n    }\n\n    &__title {\n      font-weight: bold;\n    }\n  }\n\n  .yard-confirmation-form {\n    display: flex;\n    flex-direction: column;\n    margin-top: 10px;\n\n    & > * {\n      margin: 5px 0;\n    }\n\n    button {\n      width: 80px;\n    }\n\n    textarea {\n      padding: 20px;\n      border: 1px solid var(--color-grayscale-5);\n      width: 50%;\n      height: 200px;\n      outline: none;\n    }\n\n    label {\n      font-weight: 500;\n    }\n  }\n}\n",null],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
