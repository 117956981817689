import React from 'react';
import moment from 'moment';
import CopyButton from '@client/components/CopyButton';
import PhoneButton from '@client/components/PhoneButton';
import { legStatusesToUIMappings } from '@client/constants';

export interface MatchRowMatch {
  key: string | number;
  legNumber: string | number;
  status: string;
  driverName?: string;
  driverPhone?: string;
  containerNumber: string;
  lastFreeDay: string;
  firstStopName: string;
  firstStopStart: string;
}

interface MatchRowProps {
  match: MatchRowMatch;
  isOnHire: boolean;
}

const formatDate = (dt: string, dateOnly: boolean = false): string => {
  const m = moment(dt);
  if (m.isValid()) {
    return m.format(`MM/DD/YYYY${dateOnly ? '' : ' HH:mm'}`);
  }
  return dt;
};

function MatchRow(props: MatchRowProps) {
  return (
    <tr key={props.match.key}>
      <td>
        {props.match.legNumber} <CopyButton value={`${props.match.legNumber}`} />
      </td>
      <td>{legStatusesToUIMappings[props.match.status]}</td>
      <td>
        {props.match.driverName}
        <span>
          <PhoneButton
            id={props.match.driverPhone}
            copyValue={props.match.driverPhone}
            toolTipValue={props.match.driverPhone}
            screen="dashboard/admin/available-yard-containers"
          />
        </span>
      </td>
      <td>
        {props.match.containerNumber} <CopyButton value={`${props.match.containerNumber}`} />
      </td>
      <td>{formatDate(props.match.lastFreeDay, true)}</td>
      <td className={props.isOnHire ? '' : 'YardContainers__emptyReturnMatchesTable-shift-list-item--red'}>
        {props.match.firstStopName}
      </td>
      <td>{formatDate(props.match.firstStopStart)}</td>
    </tr>
  );
}

export default MatchRow;
