import { createSlice } from '@reduxjs/toolkit';
import getInitialAsyncSliceState from '@client/_blessed/store/utils/getInitialAsyncAtomState';
import {
  retrieveYardContainersAction,
  loadTableDataAction,
  retrieveSmartMatchesAction,
  loadGeneratorMatches
} from './asyncActions';
import { sliceID } from './utils';

export interface YardContainersState {
  yardContainers: ReturnType<typeof getInitialAsyncSliceState>;
  smartMatches: ReturnType<typeof getInitialAsyncSliceState>;
  generatorMatches: ReturnType<typeof getInitialAsyncSliceState>;
  emptyReturnsSupported: any[];
  search: string;
  tableSort: {
    column: string;
    direction: string;
  };
  filters: Record<string, any>;
  tableDataLoading: number;
}

function getInitialState(state: any = {}) {
  state.yardContainers = getInitialAsyncSliceState();
  state.smartMatches = getInitialAsyncSliceState();
  state.generatorMatches = getInitialAsyncSliceState();
  state.smartMatches.data = {};
  state.emptyReturnsSupported = [];
  state.search = '';
  state.tableSort = {
    column: 'containerNumber',
    direction: 'ASC'
  };
  state.filters = {};
  state.tableDataLoading = 0;

  return state;
}

export default createSlice({
  name: sliceID,
  initialState: getInitialState(),
  reducers: {
    reset: (state) => getInitialState(state),
    search: (state, action) => {
      state.search = action.payload;
    },
    filter: (state, action) => {
      state.filters = action.payload;
    },
    sortTable: (state, action) => {
      state.tableSort = action.payload;
    }
  },
  extraReducers: (builder) => {
    // loadTableData
    builder.addCase(loadTableDataAction.pending, (state) => {
      state.tableDataLoading += 1;
    });
    builder.addCase(loadTableDataAction.fulfilled, (state) => {
      state.tableDataLoading -= 1;
    });
    builder.addCase(loadTableDataAction.rejected, (state) => {
      state.tableDataLoading -= 1;
    });

    // retrieveYardContainers
    builder.addCase(retrieveYardContainersAction.pending, (state) => {
      state.yardContainers.error = null;
      state.yardContainers.loading += 1;
    });
    builder.addCase(retrieveYardContainersAction.fulfilled, (state, action) => {
      // entityAdapter.setAll(state.yardContainers, action.payload?.yardContainers);
      state.yardContainers.data = action.payload?.data.containers;
      state.yardContainers.emptyReturnsSupported = action.payload?.data.emptyReturnsSupported;
      state.yardContainers.error = null;
      state.yardContainers.loading -= 1;
    });
    builder.addCase(retrieveYardContainersAction.rejected, (state, action) => {
      state.yardContainers.error = action.payload;
      state.yardContainers.loading -= 1;
    });

    // outgate matches
    builder.addCase(retrieveSmartMatchesAction.pending, (state) => {
      state.smartMatches.loading += 1;
      state.smartMatches.error = null;
    });
    builder.addCase(retrieveSmartMatchesAction.fulfilled, (state, action) => {
      state.smartMatches.loading -= 1;
      const payload: any = action.payload as any;
      state.smartMatches.data = payload.data;
      state.smartMatches.error = null;
    });
    builder.addCase(retrieveSmartMatchesAction.rejected, (state, action) => {
      state.smartMatches.loading -= 1;
      state.smartMatches.error = action.payload;
    });

    // generator outgate matches
    builder.addCase(loadGeneratorMatches.pending, (state) => {
      state.generatorMatches.loading += 1;
      state.generatorMatches.error = null;
    });
    builder.addCase(loadGeneratorMatches.fulfilled, (state, action) => {
      state.generatorMatches.loading -= 1;
      state.generatorMatches.data = action.payload.data;
      state.generatorMatches.error = null;
    });
    builder.addCase(loadGeneratorMatches.rejected, (state, action) => {
      state.generatorMatches.loading -= 1;
      state.generatorMatches.error = action.payload;
    });
  }
});
