import { createSelector } from 'reselect';
import type { RootState } from '@client/reduxProvider';

export const selectShipETAsSlice = (state: RootState) => state.admin.shipETAs;

export const selectShipETAs = createSelector([selectShipETAsSlice], (data) => data.activeShipETAs);

export const fetchActiveShipETAsLoading = createSelector(
  [selectShipETAsSlice],
  (data) => data.fetchActiveShipETAsLoading
);

export const setActiveShipETAsLoading = createSelector([selectShipETAsSlice], (data) => data.setActiveShipETAsLoading);
