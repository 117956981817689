import { useCallback, useState } from 'react';
import moment from 'moment';
import Drawer from '../../../../../../components/Drawer';
import EditableField from '../../../../../../components/EditableField';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import Button from '../../../../../../components/Button';
import { SetActiveShipETAsData } from './store/asyncActions';
import { ActiveShipEta, ActiveShipETAsState } from './store';

import './style.css';

interface ShipETAsProps {
  tableData: ActiveShipETAsState['activeShipETAs'];
  tableDataLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSave: (data: SetActiveShipETAsData) => Promise<void>;
}

// Sort these last in the list
const SORT_LAST = ['N/A', 'NA', 'NONE'];

function ShipETAs(props: ShipETAsProps) {
  const baseClassName = 'ShipETAs';

  const [selectedShip, selectShip] = useState<ActiveShipEta | null>(null);
  const [newEta, setNewEta] = useState<string | null>(null);

  const updateShipEta = useCallback(
    (date: string | null) => {
      setNewEta(date);
    },
    [setNewEta]
  );

  if (props.tableDataLoading || !props.tableData) {
    return <LoadingSpinner className={`${baseClassName}-spinner`} />;
  }

  const rows = [...props.tableData]
    .sort((a: any, b: any) => {
      if (SORT_LAST.includes(a.steamShipName) && !SORT_LAST.includes(b.steamShipName)) {
        return 1;
      }

      if (!SORT_LAST.includes(a.steamShipName) && SORT_LAST.includes(b.steamShipName)) {
        return -1;
      }

      return 0;
    })
    .map((d) => (
      <tr
        className={selectedShip && selectedShip.steamShipName === d.steamShipName ? `${baseClassName}__selected` : ''}
        key={d.steamShipName}
        onClick={() => selectShip(d)}
      >
        <td>{d.steamShipName}</td>
        <td>{d.shipArrivalEtas.join(', ')}</td>
        <td>{d.orderCount}</td>
      </tr>
    ));

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__title`}>Active Ship ETAs</div>
      <table className={`${baseClassName}__table`}>
        <thead>
          <tr>
            <th>Ship Name</th>
            <th>ETA(s)</th>
            <th>Order Count</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <Drawer
        anchor="right"
        contentClassName={
          selectedShip
            ? 'right-sidebar-navigation__content right-sidebar-navigation__content-open'
            : 'right-sidebar-navigation__content'
        }
        open={!!selectedShip}
        onClose={(e) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
          }
          selectShip(null);
        }}
      >
        {selectedShip && (
          <div className="sidebar-content">
            <div className="sidebar-content-section">
              <div className="sidebar-content-section_title">Ship Name:</div>
              <div className="sidebar-content-section_content">{selectedShip.steamShipName}</div>
            </div>
            <div className="sidebar-content-section">
              <div className="sidebar-content-section_title">Current ETA(s):</div>
              <ul>
                {selectedShip.shipArrivalEtas.map((eta: string) => (
                  <li key={eta} className="sidebar-content-section_content">
                    {eta}
                  </li>
                ))}
              </ul>
            </div>
            <div className="sidebar-content-section">
              <div className="sidebar-content-section_title">New ETA:</div>
              <EditableField
                className="sidebar-content-date_input"
                type="date"
                editable
                dataField="shipETA"
                dataResourceType="ship"
                placeholder="Enter new ETA"
                displayFormatter={(value: any) => (value ? moment(value).format('MM/DD/YYYY') : '')}
                alwaysSave
                onSave={updateShipEta}
              />
            </div>
            <Button
              async
              label="Save"
              className="sidebar-content-btn"
              disabled={newEta === null}
              onClick={async () => {
                await props.onSave({ steamShipName: selectedShip.steamShipName, updatedETA: newEta! });
                setNewEta(null);
                selectShip(null);
              }}
            />
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default ShipETAs;
