import React, { useEffect, useState } from 'react';

import DropdownSelectInput from '@client/components/DropdownSelectInput';
import Button from '@client/components/Button';
import Tag from '@client/components/Tag';
import { logAnalyticsClick } from '@client/utils/analytics';

import './style.css';
import { useSelector } from 'react-redux';
import { selectDepartmentUuidMap } from '@client/_blessed/store/entities';

interface YardContainersFiltersProps {
  yards: any;
  shippingLines: any;
  sizes: any;
  terminalOutgates: any;
  onFilterChange?: (filters: any) => any;
  terminalIngates: any;
}

function YardContainersFilters(props: YardContainersFiltersProps) {
  const [state, setState]: any = useState({
    filters: {
      yards: [],
      terminalOutgates: [],
      terminalIngates: [],
      shippingLines: [],
      sizes: []
    },
    ddValueRefs: {
      yards: null,
      terminalOutgates: null,
      shippingLines: null,
      sizes: null
    }
  });
  const departmentUuidMap = useSelector(selectDepartmentUuidMap);

  function getDepartmentNames(values: string[]): string[] {
    const departmentNames = values.reduce((acc: string[], value) => {
      const departmentName = departmentUuidMap[value]?.nickname;
      if (departmentName) {
        acc.push(departmentName);
      }
      return acc;
    }, []);
    return departmentNames;
  }

  const baseClassName = 'YardContainersFilters';
  const classNames = {
    root: baseClassName,
    tags: `${baseClassName}__tags`,
    inputs: `${baseClassName}__inputs`,
    input: `${baseClassName}__inputs-input`,
    footer: `${baseClassName}__footer`
  };

  const handleDropdownChange = (selectedValues: any, cProps: any) => {
    state.ddValueRefs[cProps.groupName] = selectedValues;
    const { groupName } = cProps || null;

    const values = selectedValues.map((item: any) => item.value);

    // To get name for terminals and shipping lines
    // Yard filter does not use uuid
    const departmentNames = getDepartmentNames(values);

    logAnalyticsClick(`dropdown ${groupName}`, 'admin/available-yard-containers', {
      dropdown: groupName,
      values: departmentNames.length > 0 ? departmentNames : values
    });

    setState((prevState: any) => ({
        ...prevState,
        filters: {
          ...state.filters,
          [cProps.groupName]: values
        }
      }));
  };

  const handleClearFilterBtnClick = () => {
    state.ddValueRefs.yards?.splice(0, state.ddValueRefs.yards?.length);
    state.ddValueRefs.terminalOutgates?.splice(0, state.ddValueRefs.terminalOutgates?.length);
    state.ddValueRefs.terminalIngates?.splice(0, state.ddValueRefs.terminalIngates?.length);
    state.ddValueRefs.shippingLines?.splice(0, state.ddValueRefs.shippingLines?.length);
    state.ddValueRefs.sizes?.splice(0, state.ddValueRefs.sizes?.length);

    logAnalyticsClick('clear filter', 'admin/available-yard-containers');

    setState((prevState: any) => ({
        ...prevState,
        filters: {}
      }));
  };

  const handleDeleteFilter = (cProps: any) => {
    state.ddValueRefs[cProps.groupName]?.splice(cProps.index, 1);
    const groupFilters = Array.isArray(state.filters[cProps.groupName]) ? [...state.filters[cProps.groupName]] : [];
    groupFilters.splice(cProps.index, 1);

    setState((prevState: any) => ({
        ...prevState,
        filters: {
          ...state.filters,
          [cProps.groupName]: groupFilters
        }
      }));
  };

  useEffect(() => {
    if (props.onFilterChange) {
      props.onFilterChange(state.filters);
    }
  }, [state.filters]);

  return (
    <div className={classNames.root}>
      <div className={classNames.tags}>
        {state.filters.yards?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="yards"
              label={`Yard: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}

        {state.filters.terminalOutgates?.map((item: any, index: number) => {
          const option = props.terminalOutgates.find((opt: any) => opt.value === item);

          return (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="terminalOutgates"
              label={`Terminal Outgate: ${option.name}`}
              onDelete={handleDeleteFilter}
            />
          );
        })}

        {state.filters.terminalIngates?.map((item: any, index: number) => {
          const option = props.terminalIngates.find((opt: any) => opt.value === item);

          return (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="terminalIngates"
              label={`Terminal Ingate: ${option.name}`}
              onDelete={handleDeleteFilter}
            />
          );
        })}

        {state.filters.shippingLines?.map((item: any, index: number) => {
          const option = props.shippingLines.find((opt: any) => opt.value === item);

          return (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="shippingLines"
              label={`Shipping Line: ${option.name}`}
              onDelete={handleDeleteFilter}
            />
          );
        })}

        {state.filters.sizes?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="sizes"
              label={`Size: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}
      </div>

      <div className={classNames.inputs}>
        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Yard"
          groupName="yards"
          values={state.filters.yards}
          labelField="name"
          valueField="value"
          options={props.yards}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Terminal Outgate"
          groupName="terminalOutgates"
          values={state.filters.terminalOutgates}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.terminalOutgates}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Terminal Ingate"
          groupName="terminalIngates"
          values={state.filters.terminalIngates}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.terminalIngates}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Shipping Lines"
          groupName="shippingLines"
          values={state.filters.shippingLines}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.shippingLines}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Size"
          groupName="sizes"
          values={state.filters.sizes}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.sizes}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <div className={classNames.footer}>
          <Button theme="2" label="Clear Filters" onClick={handleClearFilterBtnClick} />
        </div>
      </div>
    </div>
  );
}

export default YardContainersFilters;
