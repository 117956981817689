import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActiveShipEta } from '.';
import SystemToast from '../../../../../../../components/SystemToast';
import { raiseToast, TOAST_POSITION } from '../../../../../../../components/Toaster';
import { handleCustomError } from '../../../../../../../utils/errors';
import request from '../../../../../../../utils/request';
import { api } from '../../../../../../../utils/url';

interface ShipETAResponse {
  data: ActiveShipEta[];
}

export const fetchActiveShipETAsAction = createAsyncThunk(`shipETAs/fetchActiveShipETAs`, async (_: void, thunkAPI) => {
  const url = api('/ship/active-etas');

  try {
    const result = (await request(url)) as ShipETAResponse;

    return result;
  } catch (err) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={handleCustomError(err, 'Unable to fetch active ship ETAs')}
      />,
      { position: TOAST_POSITION.BOTTOM_LEFT }
    );
    thunkAPI.rejectWithValue(err);
  }
});

export interface SetActiveShipETAsData {
  steamShipName: string;
  updatedETA: string;
}
export const setActiveShipETAsAction = createAsyncThunk(
  `shipETAs/setActiveShipETAs`,
  async (data: SetActiveShipETAsData[], thunkAPI) => {
    const url = api('/ship/active-etas');

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data
      })
    };

    try {
      const result = (await request(url, options)) as Record<'data', Record<'ordersUpdatedCount', number>>;

      raiseToast(
        <SystemToast type={SystemToast.Type.SUCCESS} message={`Updated ${result.data.ordersUpdatedCount} order(s)`} />,
        { position: TOAST_POSITION.BOTTOM_LEFT }
      );

      return result;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to fetch active ship ETAs')}
        />,
        { position: TOAST_POSITION.BOTTOM_LEFT }
      );
      thunkAPI.rejectWithValue(err);
    }
  }
);
