import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet';

import { selectTerminals } from '@client/_blessed/store/entities/departments';
import { logAnalyticsClick, logAnalyticsPageView } from '@client/utils/analytics';
import { selectContainerOnHireStatusesByContainerNumber } from '@client/_blessed/store/entities';
import { fetchContainerYardConfirmations } from '@client/_blessed/store/entities/containerYardConfirmations';

import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import store from './store';
import {
  loadTableDataAction,
  retrieveContainerSizesAction,
  loadSmartMatches,
  loadGeneratorMatches
} from './store/asyncActions';
import {
  selectTableDataLoading,
  selectTableSort,
  tableDataSelector,
  selectContainerSizesBySize,
  selectSmartMatches,
  selectEmptyReturnsSupported,
  selectGeneratorMatches
} from './store/selectors';

function AvailableEmptyYardContainers() {
  const dispatch = useAppDispatch();
  const tableData = useSelector(tableDataSelector);
  const terminals = useSelector(selectTerminals).reduce((carry: Record<string, any>, current) => {
    carry[current.uuid] = current;
    return carry;
  }, {});
  const smartMatches = useSelector(selectSmartMatches);
  const generatorMatches = useSelector(selectGeneratorMatches);
  const containerSizes = useSelector(selectContainerSizesBySize);
  const emptyReturnsSupported = useSelector(selectEmptyReturnsSupported);
  const tableSort = useSelector(selectTableSort);
  const tableDataLoading = useSelector(selectTableDataLoading);
  const onHireStatusesByContainer = useSelector(selectContainerOnHireStatusesByContainerNumber);
  const featFlags = useFlags();
  const useNewBundles = !!featFlags.da6566?.yardEmpties;

  const handleLoadTableData = () => {
    dispatch(loadTableDataAction());
  };

  const handleLoadSmartMatches = ({ ssl, size, legNumber }: { ssl: string; size: string; legNumber: number }) => {
    if (useNewBundles) {
      dispatch(loadGeneratorMatches({ legNumber }));
    } else {
      dispatch(loadSmartMatches({ ssls: [ssl], size }));
    }
  };

  const handleRefresh = () => {
    logAnalyticsClick('refresh', 'admin/available-yard-containers');
    handleLoadTableData();
  };

  const handleSearch = (value: any) => {
    logAnalyticsClick('search', 'admin/available-yard-containers', { value });
    dispatch(store.actions.search(value));
  };

  const handleTableSort = (value: any) => {
    // Should we add sort analytics?
    dispatch(store.actions.sortTable(value));
  };

  useEffect(() => {
    logAnalyticsPageView('admin/available-yard-containers');
    handleLoadTableData();

    const containerSideActionPromise = dispatch(retrieveContainerSizesAction());
    const containerYardConfirmationPromise = dispatch(fetchContainerYardConfirmations());

    return () => {
      containerSideActionPromise.abort();
      containerYardConfirmationPromise.abort();
      dispatch(store.actions.reset());
    };
  }, []);

  return (
    <>
      <Helmet title="Yard Empties" />
      <Component
        tableData={tableData}
        containerSizes={containerSizes}
        terminals={terminals}
        emptyReturnsSupported={emptyReturnsSupported}
        tableDataLoading={tableDataLoading}
        tableSort={tableSort}
        onRefresh={handleRefresh}
        onSearch={handleSearch}
        onTableSort={handleTableSort}
        onLoadSmartMatches={handleLoadSmartMatches}
        smartMatches={smartMatches}
        generatorMatches={generatorMatches}
        useNewBundles={useNewBundles}
        onHireStatusesByContainer={onHireStatusesByContainer}
      />
    </>
  );
}

export default React.memo(AvailableEmptyYardContainers);
