import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import { retrieveYardContainers } from '@client/_blessed/services/yardContainers/retrieveYardContainers';
import {
  fetchGeneratorSmartMatches,
  retrieveSmartMatches
} from '@client/_blessed/services/yardContainers/retrieveSmartMatches';
import { fetchContainerSizes } from '@client/_blessed/store/entities/containerSizes';
import { fetchGeneratorLegs } from '@client/_blessed/services/reactiveDualTrans/retrieveDualInMatches';
import { GeneratorBundle } from '@client/_blessed/store/entities';
import { sliceID } from './utils';

export const retrieveSmartMatchesAction = createAsyncThunk(
  `${sliceID}/retrieveSmartMatches`,
  async (params: { ssls: string[]; size: string }, thunkAPI) => {
    try {
      const resp = await retrieveSmartMatches(params);
      return await thunkAPI.dispatch(resp);
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load chassis sizes" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const retrieveContainerSizesAction = createAsyncThunk(
  `${sliceID}/retrieveContainerSizes`,
  async (params, thunkAPI) => {
    try {
      await thunkAPI.dispatch(fetchContainerSizes());
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load chassis sizes" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const retrieveYardContainersAction = createAsyncThunk<{ data: any }>(
  `${sliceID}/retrieveYardContainers`,
  async (params, thunkAPI) => {
    try {
      // each request would need a unique key to abort
      const resp: any = await retrieveYardContainers(null, {
        abortKey: `${sliceID}/retrieveYardContainers`
      });
      return { data: { containers: resp.data, emptyReturnsSupported: resp.emptyReturnsSupported } };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable retrieve yard containers" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadTableDataAction = createAsyncThunk(`${sliceID}/loadTableData`, async (params, thunkAPI) => {
  try {
    await thunkAPI.dispatch(retrieveYardContainersAction());
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load table data" />);
    return thunkAPI.rejectWithValue(err);
  }
});

export const loadSmartMatches = createAsyncThunk(
  `${sliceID}/loadSmartMatches`,
  async (params: { ssls: string[]; size: string }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(retrieveSmartMatches(params));
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load outgate matches" />);
      console.error(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadGeneratorMatches = createAsyncThunk<{ data: any }, any>(
  `${sliceID}/loadGeneratorSmartMatches`,
  async (params: { legNumber: number }, thunkAPI) => {
    try {
      const bundleResp: any = await fetchGeneratorSmartMatches(params);
      const legNumbersToFetch: number[] = [];
      bundleResp.data.forEach((item: GeneratorBundle) => {
        const [, legNumber] = item.id.split('::');
        legNumbersToFetch.push(Number(legNumber));
      });
      const legResp: any = await fetchGeneratorLegs(legNumbersToFetch);
      return { data: legResp.data };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load outgate matches" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
