import { YardContainer } from '@client/_blessed/components/features/Admin/features/YardContainers/components/YardConfirmationSidebarButton/types';
import {
  putContainerYardConfirmation,
  PutContainerYardConfirmationRequest
} from '@client/_blessed/store/entities/containerYardConfirmations';
import { useContext } from 'react';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { appContext } from '@client/components/App/contexts';
import Drawer from '@client/components/Drawer';
import { logAnalyticsClick } from '@client/utils/analytics';
import YardConfirmationSidebar from './component';

const getPutContainerYardConfirmationRequest = (yardContainer: YardContainer): PutContainerYardConfirmationRequest => ({
  confirmedBy: yardContainer.containerYardConfirmation?.confirmedBy,
  reservedBy: yardContainer.containerYardConfirmation?.reservedBy,
  containerNumber: yardContainer.containerYardConfirmation?.containerNumber,
  notes: yardContainer.containerYardConfirmation?.notes
});

function YardConfirmationSidebarContainer({ yardContainer, onClose }: { yardContainer: any; onClose: () => void }) {
  const dispatch = useAppDispatch();
  const { state: appState } = useContext(appContext);
  const userInfo = appState['auth.userInfo'];
  const showDrawer = !!yardContainer;

  const onConfirm = () => {
    const putContainerYardConfirmationRequest = getPutContainerYardConfirmationRequest(yardContainer);
    dispatch(
      putContainerYardConfirmation({
        ...putContainerYardConfirmationRequest,
        confirmedBy: userInfo?.uuid
      })
    );
    logAnalyticsClick('yard confirmation -- confirm', 'dashboard/admin/available-yard-containers');
  };

  const onCancelConfirmation = () => {
    const putContainerYardConfirmationRequest = getPutContainerYardConfirmationRequest(yardContainer);
    dispatch(
      putContainerYardConfirmation({
        ...putContainerYardConfirmationRequest,
        confirmedBy: undefined
      })
    );
    logAnalyticsClick('yard confirmation -- confirm cancellation', 'dashboard/admin/available-yard-containers');
  };

  const onReserve = () => {
    const putContainerYardConfirmationRequest = getPutContainerYardConfirmationRequest(yardContainer);
    dispatch(
      putContainerYardConfirmation({
        ...putContainerYardConfirmationRequest,
        reservedBy: userInfo?.uuid
      })
    );
    logAnalyticsClick('yard confirmation -- reserve', 'dashboard/admin/available-yard-containers');
  };

  const onCancelReservation = () => {
    const putContainerYardConfirmationRequest = getPutContainerYardConfirmationRequest(yardContainer);
    dispatch(
      putContainerYardConfirmation({
        ...putContainerYardConfirmationRequest,
        reservedBy: undefined
      })
    );
    logAnalyticsClick('yard confirmation -- reserve cancellation', 'dashboard/admin/available-yard-containers');
  };

  const onSubmitNotes = (notes: string) => {
    const putContainerYardConfirmationRequest = getPutContainerYardConfirmationRequest(yardContainer);
    dispatch(
      putContainerYardConfirmation({
        ...putContainerYardConfirmationRequest,
        notes
      })
    );
    logAnalyticsClick('yard notes', 'dashboard/admin/available-yard-containers', { notes: notes || null });
  };

  return (
    <Drawer open={showDrawer} anchor="right" onClose={onClose}>
      <YardConfirmationSidebar
        yardContainer={yardContainer}
        onConfirm={onConfirm}
        onCancelConfirmation={onCancelConfirmation}
        onReserve={onReserve}
        onCancelReservation={onCancelReservation}
        onSubmitNotes={onSubmitNotes}
      />
    </Drawer>
  );
}

export default YardConfirmationSidebarContainer;
