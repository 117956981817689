// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin {
  display: flex;
  height: 100%;
  width: 100%;
}

  .Admin__content {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    width: calc(100vw - 200px);
    max-height: calc(100vh - 100px);
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AASb;;EAPE;IACE,aAAa;IACb,YAAY;IACZ,cAAc;IACd,0BAA0B;IAC1B,+BAA+B;EACjC","sourcesContent":[".Admin {\n  display: flex;\n  height: 100%;\n  width: 100%;\n\n  &__content {\n    display: flex;\n    flex-grow: 1;\n    overflow: auto;\n    width: calc(100vw - 200px);\n    max-height: calc(100vh - 100px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
