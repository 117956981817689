import { createSlice } from '@reduxjs/toolkit';
import { fetchActiveShipETAsAction, setActiveShipETAsAction } from './asyncActions';

export const shipETASliceId = 'shipETAs';

export interface ActiveShipEta {
  orderCount: number;
  shipArrivalEtas: string[];
  steamShipName: string;
}
export interface ActiveShipETAsState {
  fetchActiveShipETAsLoading: boolean;
  setActiveShipETAsLoading: boolean;
  activeShipETAs?: ActiveShipEta[] | null;
}

const getInitialState = (): ActiveShipETAsState => ({
  fetchActiveShipETAsLoading: true,
  setActiveShipETAsLoading: true,
  activeShipETAs: null
});

const shipETAsSlice = createSlice({
  name: `dispatch/${shipETASliceId}`,
  initialState: getInitialState(),
  reducers: {
    reset: () => getInitialState()
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActiveShipETAsAction.pending, (state) => {
      state.fetchActiveShipETAsLoading = true;
    });
    builder.addCase(fetchActiveShipETAsAction.fulfilled, (state, action) => {
      state.fetchActiveShipETAsLoading = false;
      state.activeShipETAs = action.payload?.data || null;
    });
    builder.addCase(fetchActiveShipETAsAction.rejected, (state) => {
      state.fetchActiveShipETAsLoading = false;
    });
    builder.addCase(setActiveShipETAsAction.pending, (state) => {
      state.setActiveShipETAsLoading = true;
    });
    builder.addCase(setActiveShipETAsAction.fulfilled, (state) => {
      state.setActiveShipETAsLoading = false;
    });
    builder.addCase(setActiveShipETAsAction.rejected, (state) => {
      state.setActiveShipETAsLoading = false;
    });
  }
});

export default shipETAsSlice;
