import { createSelector } from '@reduxjs/toolkit';
import { ContainerSize, selectContainerSizes } from '@client/_blessed/store/entities';
import {
  ContainerYardConfirmationResponse,
  selectContainerYardConfirmationsMap
} from '@client/_blessed/store/entities/containerYardConfirmations';
import type { RootState } from '@client/reduxProvider';
import { filterWith, sliceID, sortBy } from './utils';
import { getName, filterMutedAndUnvailableContainers } from '../utils';
import { YardContainersState } from '.';

export const selectYardContainersPage = (state: any): YardContainersState => state.admin[sliceID];

export const selectYardContainers = (state: RootState) => selectYardContainersPage(state).yardContainers.data;

export const selectSmartMatches = (state: RootState) => selectYardContainersPage(state).smartMatches.data;

export const selectGeneratorMatches = (state: RootState) => selectYardContainersPage(state).generatorMatches.data;

export const selectEmptyReturnsSupported = (state: RootState) => selectYardContainersPage(state).emptyReturnsSupported;

export const selectContainerSizesBySize = (state: any): Record<string, ContainerSize> =>
  (Object.values(selectContainerSizes(state).entities) as ContainerSize[]).reduce(
    (carry: Record<string, ContainerSize>, current: ContainerSize) => {
      carry[`${current.length}${current.type}`] = current;
      carry[`${current.length}${current.type}`.toUpperCase()] = current;
      return carry;
    },
    {} as Record<string, ContainerSize>
  );

export const selectSearch = (state: any) => selectYardContainersPage(state).search;

export const selectTableSort = (state: any) => selectYardContainersPage(state).tableSort;

export const selectfilters = (state: any) => selectYardContainersPage(state).filters;

export const selectTableDataLoading = (state: any) => !!selectYardContainersPage(state).tableDataLoading;

export const selectGeneratorMatchesLoading = (state: any) => {
  const generatorMatchesLoading = !!selectYardContainersPage(state).generatorMatches?.loading;
  return generatorMatchesLoading;
};

function addYardConfirmations(
  containers: any[],
  containerYardConfirmationsMap: Record<string, ContainerYardConfirmationResponse>
) {
  return containers.map((container: any) => {
    const containerNumber = container.containerNumber?.trim()?.toUpperCase();
    const containerYardConfirmation = containerYardConfirmationsMap[containerNumber];
    const confirmation = containerYardConfirmation?.confirmedBy;
    const reservedByUserName = getName(containerYardConfirmation?.reservedByUser);

    return {
      ...container,
      containerNumber,
      containerYardConfirmation,
      confirmed: !!confirmation,
      inHub: true,
      notes: containerYardConfirmation?.notes,
      checkInDate: containerYardConfirmation?.checkInDate,
      reservedByUserName
    };
  });
}

const addContainersFromYardIntegration = (
  containers: any[],
  containerYardConfirmationsMap: Record<string, ContainerYardConfirmationResponse>
) => {
  const clonedContainers = [...containers];
  const containerNumbersInHub = new Set(containers.map((container) => container.containerNumber));
  const containersWithYardConfirmations = Object.keys(containerYardConfirmationsMap);
  containersWithYardConfirmations.forEach((container) => {
    const containerIsNotInHub = !containerNumbersInHub.has(container);
    const containerYardConfirmation = containerYardConfirmationsMap[container];
    const confirmation = containerYardConfirmation?.confirmedBy;
    const reservedByUserName = getName(containerYardConfirmation?.reservedByUser);

    if (containerIsNotInHub) {
      clonedContainers.push({
        containerNumber: container,
        containerYardConfirmation,
        confirmed: !!confirmation,
        inHub: false,
        notes: containerYardConfirmation?.notes,
        checkInDate: containerYardConfirmation?.checkInDate,
        reservedByUserName
      });
    }
  });

  return clonedContainers;
};

export const selectContainersWithConfirmationsHelper = (
  containers: any[],
  containerYardConfirmationsMap: Record<string, ContainerYardConfirmationResponse>
) => {
  if (!containers?.length) return [];
  const containersWithYardConfirmations = addYardConfirmations(containers, containerYardConfirmationsMap);
  const containersWithYardIntegration = addContainersFromYardIntegration(
    containersWithYardConfirmations,
    containerYardConfirmationsMap
  );

  return containersWithYardIntegration;
};

const containersWithConfirmationsSelector = createSelector(
  [selectYardContainers, selectContainerYardConfirmationsMap],
  selectContainersWithConfirmationsHelper
);

export const sortedContainersSelector = createSelector(
  [containersWithConfirmationsSelector, selectTableSort],
  (containers: any, tableSort: any) => {
    let result = [];

    if (containers?.length) {
      const sortedContainers = [...containers].sort(sortBy(tableSort));

      result = sortedContainers;
    }
    return result;
  }
);

export const searchedContainersSelector = createSelector(
  [sortedContainersSelector, selectSearch],
  (containers: any, search: string) => {
    let result = [];

    if (containers?.length) {
      const searchedContainers = search ? containers.filter(filterWith(search)) : containers;

      result = searchedContainers;
    }

    return result;
  }
);

export const selectTableDataHelper = (containers: any, filters: any) => {
  const result: any[] = [];
  if (containers?.length) {
    containers.forEach((container: any) => {
      let match = true;
      if (
        filters.terminalOutgates?.length &&
        !filters.terminalOutgates.find((opt: any) => opt === container.outgateTerminalUuid)
      ) {
        match = false;
      }

      if (
        filters.terminalIngates?.length &&
        !filters.terminalIngates.find((opt: any) => opt === container.terminalUuid)
      ) {
        match = false;
      }

      if (filters.shippingLines?.length && !filters.shippingLines.find((opt: any) => opt === container.sslUuid)) {
        match = false;
      }

      if (filters.sizes?.length && !filters.sizes.find((opt: any) => opt === container.size)) {
        match = false;
      }

      if (filters.yards?.length && !filters.yards.find((opt: any) => opt === container.yardName)) {
        match = false;
      }

      if (match) {
        result.push(container);
      }
    });
  }

  return result;
};

export const tableDataSelector = createSelector([searchedContainersSelector, selectfilters], selectTableDataHelper);

export const tableDataContainerNumbersSelector = ({
  showMuted,
  showAllContainers,
  mutedItems
}: {
  showMuted: boolean;
  showAllContainers: boolean;
  mutedItems: string[];
}) =>
  createSelector(tableDataSelector, (tableData) => {
    const filteredTableData = filterMutedAndUnvailableContainers({
      showAllContainers,
      showMuted,
      tableData,
      mutedItems
    });
    const containerNumbers = filteredTableData.map((td) => td.containerNumber);

    return containerNumbers;
  });

export const yardsSelector = createSelector([sortedContainersSelector], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const dupeCache: any = {};
    containers.forEach((item: any) => {
      if (!dupeCache[item.yardName]) {
        dupeCache[item.yardName] = true;

        result.push({
          name: item.yardName,
          value: item.yardName
        });
      }
    });
  }

  return result;
});

export const shippingLinesSelector = createSelector([sortedContainersSelector], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const dupeCache: any = {};
    containers.forEach((item: any) => {
      if (!dupeCache[item.sslUuid]) {
        dupeCache[item.sslUuid] = true;

        result.push({
          name: item.sslName,
          value: item.sslUuid
        });
      }
    });
  }

  return result;
});

export const sizesSelector = createSelector([sortedContainersSelector], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const dupeCache: any = {};
    containers.forEach((item: any) => {
      if (item.size && !dupeCache[item.size]) {
        dupeCache[item.size] = true;

        result.push({
          name: item.size,
          value: item.size
        });
      }
    });
  }

  return result;
});

export const terminalOutgatessSelector = createSelector([sortedContainersSelector], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const dupeCache: any = {};
    containers.forEach((item: any) => {
      if (!dupeCache[item.outgateTerminalUuid]) {
        dupeCache[item.outgateTerminalUuid] = true;

        result.push({
          name: item.outgateTerminalName,
          value: item.outgateTerminalUuid
        });
      }
    });
  }

  return result;
});

export const terminalIngatessSelector = createSelector([sortedContainersSelector], (containers: any[]) => {
  const result: any[] = [];

  if (containers?.length) {
    const dupeCache: any = {};
    containers.forEach((item: any) => {
      if (!dupeCache[item.terminalUuid]) {
        dupeCache[item.terminalUuid] = true;

        result.push({
          name: item.terminalName,
          value: item.terminalUuid
        });
      }
    });
  }

  return result;
});
