import React from 'react';
import MuteIcon from '@components/deprecatedTookit/icons/fa/regular/MicrophoneAltSlashIcon';
import UnmuteIcon from '@components/deprecatedTookit/icons/fa/regular/MicrophoneAltIcon';
import ExclamationCircleIcon from '@components/deprecatedTookit/icons/fa/regular/ExclamationCircleIcon';
import { logAnalyticsClick } from '@client/utils/analytics';
import CopyButton from '@client/components/CopyButton';
import { legStatusesToUIMappings } from '@client/constants';
import formatNumberAs, { formatNumberAsTypes } from '@client/utils/format-number-as';
import { YardContainerInfo, YardContainerInfoWithConfirmation } from '@client/_blessed/models';
import moment from 'moment-timezone';
import YardConfirmationSidebarButton from '@client/_blessed/components/features/Admin/features/YardContainers/components/YardConfirmationSidebarButton';
import classnames from 'classnames';
import YardConfirmationSidebarContainer from '@client/_blessed/components/features/Admin/features/YardContainers/components/YardConfirmationSidebar/container';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TIME_ZONES } from '@drayalliance/types';
import { getName, filterMutedAndUnvailableContainers } from '../../utils';

interface YardContainersTableProps {
  parentClassName: string;
  showMuted: boolean;
  // eslint-disable-next-line no-unused-vars
  toggleColumn: (column: keyof YardContainerInfoWithConfirmation) => void;
  sortedColumn: string;
  tableSortDirection: string;
  tableData: any[];
  muted: string[];
  // eslint-disable-next-line no-unused-vars
  selectContainer: (value: any) => void;
  selectedContainer?: any;
  // eslint-disable-next-line no-unused-vars
  setMuted: (value: string[]) => void;
  isAllowedToUseYardConfirmations: boolean;
  showAllContainers: boolean;
  offsetHeight?: number;
}

function formatAppointmentDate(container: YardContainerInfo, timezone?: TIME_ZONES) {
  const { outboundAppointmentStart, outboundAppointmentEnd } = container;
  if (!outboundAppointmentStart || !outboundAppointmentEnd) {
    return '';
  }
  const appointmentStartDateTime = moment(outboundAppointmentStart)
    .tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES)
    .format('MM/DD HH:mm');
  const appointmentEndTime = moment(outboundAppointmentEnd)
    .tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES)
    .format('HH:mm z');
  return `${appointmentStartDateTime} - ${appointmentEndTime}`;
}

function formatCheckIn(date: string, timezone?: TIME_ZONES) {
  return date
    ? moment(date)
        .tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES)
        .format('YYYY-MM-DD HH:mm z')
    : 'N/A';
}

export default function YardContainersTable({
  parentClassName,
  showMuted,
  toggleColumn,
  sortedColumn,
  tableSortDirection,
  tableData,
  muted,
  selectContainer,
  selectedContainer,
  setMuted,
  isAllowedToUseYardConfirmations,
  showAllContainers,
  offsetHeight
}: YardContainersTableProps) {
  const featureFlags = useFlags();
  const { showOrderPerDiem } = featureFlags;
  const [selectedYardContainerNumber, setSelectedYardContainerNumber] = React.useState<string | null>(null);
  const filteredTableData = filterMutedAndUnvailableContainers({
    showAllContainers,
    showMuted,
    tableData,
    mutedItems: muted
  });
  const selectedYardContainer = filteredTableData.find(
    (container) => container.containerNumber === selectedYardContainerNumber
  );
  const selectedContainerNumber = selectedContainer?.containerNumber;
  const handleSelectedYardContainerNumber = (containerNumber: string): void => {
    setSelectedYardContainerNumber(containerNumber);
    logAnalyticsClick('yard confirmation', 'admin/available-yard-containers/yard-confirmation', { containerNumber });
  };
  return (
    <>
      <table className={`${parentClassName}__table ${parentClassName}__table_${showMuted ? 'showMuted' : 'hideMuted'}`}>
        <thead className={`${parentClassName}__thead`} style={{ top: offsetHeight }}>
          <tr>
            <th>
              {showMuted ? (
                <UnmuteIcon className={`${parentClassName}__unmuteIcon`} />
              ) : (
                <MuteIcon className={`${parentClassName}__muteIcon`} />
              )}
            </th>
            <th onClick={() => toggleColumn('containerNumber')}>
              Container {sortedColumn === 'containerNumber' ? `(${tableSortDirection})` : ''}
            </th>
            {isAllowedToUseYardConfirmations && (
              <th onClick={() => toggleColumn('confirmed')}>
                In Yard {sortedColumn === 'confirmed' ? `(${tableSortDirection})` : ''}
              </th>
            )}
            <th onClick={() => toggleColumn('reservedByUserName')}>
              Reserved By {sortedColumn === 'reservedByUserName' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('outboundLegStatus')} className={`${parentClassName}__table-leg-status`}>
              Leg Status {sortedColumn === 'outboundLegStatus' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('chassisNumber')}>
              Chassis No. {sortedColumn === 'chassisNumber' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('chassisProviderName')}>
              Chassis Provider {sortedColumn === 'chassisProviderName' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('checkInDate')}>
              Check In {sortedColumn === 'checkInDate' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('terminalNickname')}>
              Terminal {sortedColumn === 'terminalNickname' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('outboundAppointmentStart')}>
              Terminal Appointment {sortedColumn === 'outboundAppointmentStart' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('outboundAppointmentNumber')}>
              Yard Appt Number {sortedColumn === 'outboundAppointmentNumber' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('yardName')}>
              Current Location {sortedColumn === 'yardName' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('isBundled')}>
              Bundled {sortedColumn === 'isBundled' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('perDiemDueDate')}>
              Per Diem Date {sortedColumn === 'perDiemDueDate' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('perDiemEstimate')}>
              Per Diem Estimate {sortedColumn === 'perDiemEstimate' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('outboundLegNumber')} className={`${parentClassName}__table-job-number`}>
              Job #{sortedColumn === 'outboundLegNumber' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('sslNickname')}>
              SSL Name {sortedColumn === 'sslNickname' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('size')}>
              Size {sortedColumn === 'size' ? `(${tableSortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('notes')}>
              Notes {sortedColumn === 'notes' ? `(${tableSortDirection})` : ''}
            </th>
          </tr>
        </thead>
        <tbody className={`${parentClassName}__tbody`}>
          {filteredTableData.map((container: any) => {
            const containerNumberButtonClassName = classnames('container-number-button', {
              'container-number-button-clickable': container.inHub,
              'container-number-button-active': selectedContainer === container
            });
            const isMuted = muted.includes(container.containerNumber);

            const handleContainerMute = () => {
              let newMuted = [...muted];
              if (!isMuted) {
                newMuted.push(container.containerNumber);
                logAnalyticsClick('Mute Yard Empty Container', 'dashboard/admin/available-yard-containers');
              } else {
                newMuted = newMuted.filter((el) => el !== container.containerNumber);
              }
              setMuted(newMuted);
            };

            return (
              <tr
                className={`${parentClassName}__table_row ${parentClassName}__table_row${isMuted ? '-muted' : ''} ${
                  selectedContainerNumber === container.containerNumber ? `${parentClassName}--selected-active-row` : ''
                }`}
                key={container.containerNumber}
              >
                <td className={`${parentClassName}__mute-option`}>
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={handleContainerMute}
                    checked={muted.includes(container.containerNumber)}
                    aria-label="Mute container"
                  />
                </td>
                <td className={`${parentClassName}__container`}>
                  <button
                    type="button"
                    onClick={() => {
                      if (container.inHub) {
                        selectContainer(container);
                      }
                    }}
                    className={containerNumberButtonClassName}
                  >
                    {container.containerNumber}
                  </button>
                  <CopyButton value={`${container.containerNumber}`} />
                </td>
                {isAllowedToUseYardConfirmations && (
                  <td className={`${parentClassName}__yard-confirm`}>
                    <div className={`${parentClassName}__yard-conf`}>
                      <YardConfirmationSidebarButton
                        yardContainer={container}
                        onClick={() => handleSelectedYardContainerNumber(container.containerNumber)}
                        active={container.containerNumber === selectedYardContainerNumber}
                        inYardText={!container.confirmed && container.containerYardConfirmation?.discrepancy}
                      />
                    </div>
                  </td>
                )}
                <td className={`${parentClassName}__reserved-by-td`}>
                  {container.containerYardConfirmation?.reservedBy && (
                    <div className={`${parentClassName}__reserved-by`}>
                      <ExclamationCircleIcon />
                      {getName(container.containerYardConfirmation?.reservedByUser)}
                    </div>
                  )}
                </td>
                <td>{legStatusesToUIMappings[container.outboundLegStatus]}</td>
                <td className={`${parentClassName}__chassis-no`}>{container.chassisNumber}</td>
                <td>{container.chassisProviderName}</td>
                <td className={`${parentClassName}__checkin-col`}>{formatCheckIn(container.checkInDate)}</td>
                <td className={`${parentClassName}__terminal-col`}>
                  {container.terminalNickname && (
                    <>
                      {container.terminalNickname} <CopyButton value={container.terminalNickname} />
                    </>
                  )}
                </td>
                <td data-testid="terminal-appointment">{formatAppointmentDate(container)}</td>
                <td>{container.outboundAppointmentNumber}</td>
                <td>{container.yardName}</td>
                <td>{container.isBundled ? 'Yes' : 'No'}</td>
                <td className={`${parentClassName}__date-col`}>{container.perDiemDueDate}</td>
                <td>
                  {container.perDiemEstimate && showOrderPerDiem
                    ? formatNumberAs(formatNumberAsTypes.currency, container.perDiemEstimate)
                    : 'N/A'}
                </td>
                <td>
                  {container.outboundLegNumber}
                  <CopyButton value={`${container.outboundLegNumber}`} />
                </td>
                <td>{container.sslNickname}</td>
                <td>{container.size}</td>
                <td>
                  <div className={`${parentClassName}__notes`}>{container.notes}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <YardConfirmationSidebarContainer
        yardContainer={selectedYardContainer}
        onClose={() => setSelectedYardContainerNumber(null)}
      />
    </>
  );
}
