// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yard-confirmation-sidebar-button-container button {
    cursor: pointer;
    padding: 5px;
    padding-right: 7px;
    display: grid;
    min-width: 105px;
    text-align: left;
    grid-template-columns: 25px 85px;
    grid-column-gap: 5px;
    align-items: center;
  }
  .yard-confirmation-sidebar-button-container .active-button {
    background: var(--color-gray-4);
    border-radius: 10px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/YardContainers/components/YardConfirmationSidebarButton/style.css"],"names":[],"mappings":"AACE;IACE,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,gCAAgC;IAChC,oBAAoB;IACpB,mBAAmB;EACrB;EAEA;IACE,+BAA+B;IAC/B,mBAAmB;EACrB","sourcesContent":[".yard-confirmation-sidebar-button-container {\n  button {\n    cursor: pointer;\n    padding: 5px;\n    padding-right: 7px;\n    display: grid;\n    min-width: 105px;\n    text-align: left;\n    grid-template-columns: 25px 85px;\n    grid-column-gap: 5px;\n    align-items: center;\n  }\n\n  .active-button {\n    background: var(--color-gray-4);\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
