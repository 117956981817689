// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-indicator {
  height: 20px;
  width: 20px;
}

  .confirmation-indicator .confirmed-icon svg {
     fill: #42c986;
    }

  .confirmation-indicator .unconfirmed-icon svg {
     fill: #e82459;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/components/YardConfirmationIndicator/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AAab;;EAVI;KACC,aAAa;IACd;;EAIA;KACC,aAAa;IACd","sourcesContent":[".confirmation-indicator {\n  height: 20px;\n  width: 20px;\n\n  .confirmed-icon {\n    svg {\n     fill: #42c986;\n    }\n  }\n\n  .unconfirmed-icon {\n    svg {\n     fill: #e82459;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
