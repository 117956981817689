import React from 'react';
import YardConfirmationIndicator from '@client/_blessed/components/features/Admin/features/components/YardConfirmationIndicator';
import { YardContainer } from '@client/_blessed/components/features/Admin/features/YardContainers/components/YardConfirmationSidebarButton/types';

import './style.css';

interface Props {
  yardContainer: YardContainer;
  active?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: (container: any) => void;
  inYardText?: string;
}

function YardConfirmationSidebarButton(props: Props) {
  const isConfirmed = props.yardContainer.confirmed;

  return (
    <div className="yard-confirmation-sidebar-button-container">
      <button className={`${props.active ? 'active-button' : ''}`} type="button" onClick={props.onClick}>
        <YardConfirmationIndicator isConfirmed={isConfirmed} showTooltip={false} />
        {props.inYardText}
      </button>
    </div>
  );
}

export default YardConfirmationSidebarButton;
