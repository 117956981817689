import React, { ReactElement } from 'react';
import Button from '@client/components/Button';
import { serializeContainersToRetrieveOrders } from '@client/utils/serializedContainersToRetrieveOrders';
import './styles.css';
import { useSelector } from 'react-redux';
import { tableDataContainerNumbersSelector, selectTableDataLoading } from '../../store/selectors';

interface Props {
  availableEmptyYardContainerListMaxLength: number;
  showAllContainers: boolean;
  showMuted: boolean;
  mutedItems: string[];
}

function OpenContainersInDispatchPageButton({
  availableEmptyYardContainerListMaxLength,
  showAllContainers,
  showMuted,
  mutedItems
}: Props): ReactElement {
  const baseClassName = 'openDispatchPageBtn';

  const tableDataContainerNumbers = useSelector(
    tableDataContainerNumbersSelector({ showMuted, showAllContainers, mutedItems })
  );
  const isDataLoading = useSelector(selectTableDataLoading);
  const serializedFilter = serializeContainersToRetrieveOrders(tableDataContainerNumbers);

  const maxLengthReached = availableEmptyYardContainerListMaxLength <= tableDataContainerNumbers.length;
  const disabled = isDataLoading || maxLengthReached;

  return (
    <div className={baseClassName}>
      {!disabled ? (
        <a
          className={`${baseClassName}__anchor`}
          href={`/hub/dashboard/dispatch?filters=${serializedFilter}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-disabled={disabled}
          aria-label="Open containers in Dispatch"
        >
          <Button label="Open containers in Dispatch" theme="1" />
        </a>
      ) : (
        <Button label="Open containers in Dispatch" theme="1" disabled={disabled} />
      )}
      {maxLengthReached && (
        <p className={`${baseClassName}--disable`}>
          Number of filtered containers is bigger than max allowed of {availableEmptyYardContainerListMaxLength}{' '}
          containers
        </p>
      )}
    </div>
  );
}

export default OpenContainersInDispatchPageButton;
