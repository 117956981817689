import { useSelector } from 'react-redux';
import { useEffect, memo } from 'react';
import { Helmet } from 'react-helmet';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import store from './store';
import { fetchActiveShipETAsLoading, selectShipETAs } from './store/selectors';
import { fetchActiveShipETAsAction, setActiveShipETAsAction, SetActiveShipETAsData } from './store/asyncActions';

function Container() {
  const dispatch = useAppDispatch();
  const tableData = useSelector(selectShipETAs);
  const tableDataLoading = useSelector(fetchActiveShipETAsLoading);

  const handleSavingEta = async (shipEta: SetActiveShipETAsData) => {
    await dispatch(setActiveShipETAsAction([shipEta]));
    await dispatch(fetchActiveShipETAsAction());
  };

  useEffect(() => {
    const promise = dispatch(fetchActiveShipETAsAction());

    return () => {
      promise.abort();
      dispatch(store.actions.reset());
    };
  }, []);

  return (
    <>
      <Helmet title="Ship ETA" />
      <Component tableDataLoading={tableDataLoading} tableData={tableData} onSave={handleSavingEta} />
    </>
  );
}

export default memo(Container);
