import TimesCircleIcon from '@components/deprecatedTookit/icons/fa/regular/TimesCircleIcon';
import CheckCircleIcon from '@components/deprecatedTookit/icons/fa/regular/CheckCircleIcon';
import React from 'react';
import SystemTooltip from '@client/components/SystemTooltip';

import './style.css';

function YardConfirmationIndicator({
  isConfirmed,
  showTooltip = true
}: {
  isConfirmed: boolean;
  showTooltip?: boolean;
}) {
  let indicatorComponent = (
    <div className="unconfirmed-icon" data-tooltip-id="unconfirmed-in-yard-tooltip" aria-label="unconfirmed in yard">
      {showTooltip && (
        <SystemTooltip id="unconfirmed-in-yard-tooltip" items={[{ message: 'Unconfirmed In Yard' }]} place="top" />
      )}
      <TimesCircleIcon />
    </div>
  );
  if (isConfirmed) {
    indicatorComponent = (
      <div className="confirmed-icon" data-tooltip-id="confirmed-in-yard-tooltip" aria-label="confirmed in yard">
        {showTooltip && (
          <SystemTooltip id="confirmed-in-yard-tooltip" items={[{ message: 'Confirmed In Yard' }]} place="top" />
        )}
        <CheckCircleIcon />
      </div>
    );
  }
  return <div className="confirmation-indicator">{indicatorComponent}</div>;
}

export default YardConfirmationIndicator;
